import React, { useEffect, useState } from 'react';
import s from './RegisterFirmForm.module.css';
import style from '../../../../special/CommonStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setDone, setFormUserEditMode, setIsAuthorized, setUser } from '../../../../rootSlice';
import { Navigate, useNavigate } from 'react-router-dom';
import { faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { RestCustomService } from 'omni-services-utils';
import { AddressForm, FormCustom, UserProcessingService, AddressFiasCustom, InfoBlockCustom, SpreadingFirm, ProcessingFirm, OfferText } from 'omni-services-common';
import { InputTextCustom, SelectCustomSearch } from 'omni-services-base';
import { CheckboxCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const RegisterFirmForm = (props) => {

    const addressRef = useRef();
    const timezoneRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const done = useSelector(state => state.done)

    const state = useSelector(state => state)

    var [name, setName] = useState('');
    var [phone, setPhone] = useState('');
    var [email, setEmail] = useState('');
    var [street, setStreet] = useState('');
    var [building, setBuilding] = useState('');
    var [offer, setOffer] = useState(false);

    var [editMode, setEditMode] = useState(false);
    var [success, setSuccess] = useState('');
    var [error, setError] = useState('');

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const handlingSubmit = (event) => {
        event.preventDefault();

        var addr = addressRef.current.getResult();
        console.log(addr);
        var firmDto = {
            number: 0,
            name: name,
            phone: phone,
            email: email,
            address: addr,
            agreements: {offer: offer },
            logo: null,
            image: null
        }
        console.log(firmDto);
        handlingFirmRegister(firmDto);
    }

    const handlingRefresh = () => {
    }

    /*useEffect(() => {
        handlingRefresh();
      });*/

      useEffect(() => {
        const someFunc = () => {
          //handlingRefresh();
        }
        someFunc()
      }, [])

    const updateUserRoles = () => {
        RestCustomService.Get(
            `/user/get`,
            null,
            true,
            (user) => {
                if (!user) {
                    return;
                }
                UserProcessingService.update(user); 
                dispatch(setUser
                    (user));
                navigate("/done");
            },
            (err) => {

            }

        );
    }

    const handlingFirmRegister = (firmDto) => {

        RestCustomService.Put(
            `/firm/user/register-new-firm`, 
            firmDto, 
            true, 
            () => {
                dispatch(setDone({
                    title: "Новая фирма!",
                    description: "Новая фирма была успешно зарегистрирована! ",
                    link: "/",
                    linkText: "Теперь можно пополнить счет и начать сотрудничество!"
                }));
                updateUserRoles();   
            }, 
            (err) => {setError(err);}
            );
    }

    const handlingFormEdit = () => {
        dispatch(setFormUserEditMode(!state.forms.user.editMode));
    }

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    const options = {
        url: "/auth",
        readOnly: false,
        submitText: "Зарегистрировать фирму",
        needToLoggedIn: true,
        showFooterButtons: true,
        showMenuButtons: false,
        editMode: true
    };

    const nameValidation = (text) => {
    
        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^[0-9a-zA-Zа-яА-ЯёЁ\s-=+!?&$%*]*$/.test(text) == false) {
            return "только буквы и пробел";
        }

        return null;
    }

    const phoneValidation = (text) => {
    
        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^[+0-9]{12}$/.test(text) == false) {
            return "формат +11 цифр: +7XXXXXXXXXX";
        }

        return null;
    }

    const emailValidation = (text) => {

        if (!text) {
            return null;
        }

        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text) == false) {
            return "неверный формат адреса почты";
        }

        return null;
    }

    const offerValidation = (text) => {

        if (!text) {
            return "значение обязательно";
        }

        return null;
    }

    const validation = () => {

        var err = null;

        err = nameValidation(name);
        if (err) {
            return "Поле имя: " + err;
        }

        err = emailValidation(email);
        if (err) {
            return "Поле телефон: " + err;
        }

        err = phoneValidation(phone);
        if (err) {
            return "Поле e-mail: " + err;
        }

        err = offerValidation(offer);
        if (err) {
            return "отметка согласия с офертой: " + err;
        }

        return null;
    }

    return (
        <div className={style.formWrapper} id={state.settings.theme == "dark" ? style.dark : style.light}>
            <FormCustom
                title={"Регистрация новой фирмы"}
                options={options}
                onSubmit={handlingSubmit}
                onEdit={handlingFormEdit}
                refresh={handlingRefresh}
                editMode={state.forms.user.editMode}
                setEditMode={setEditModeFunc}
                error={error}
                success={success}
                theme={state.settings.theme}
                validation={validation}
                body={
                    <div>
                <InfoBlockCustom info={"В данном окне, Вы можете зарегистрировать новую фирму. Клиентам она будет доступна после оплаты и настройки."}/>
                <InputTextCustom
                    controlId="name"
                    label="Название"
                    required={false}
                    type="text"
                    name="name"
                    placeholder="Название Вашей фирмы..."
                    muted=""
                    message={name}
                    readOnly={false}
                    setMessage={setName}
                    leftText={<FontAwesomeIcon icon={faUser} />}
                    theme={state.settings.theme}
                    validation={nameValidation}
                />
                <AddressFiasCustom
                    showUserLocation={true} 
                    address={null}
                    theme={state.settings.theme}
                    ref={addressRef}
                    editMode={true}
                    url={ConfigCustomService.getUrl()}
                />
                <InputTextCustom
                    controlId="phone"
                    label="Телефон"
                    required={false}
                    type="phone"
                    name="phone"
                    placeholder="Контактный телефон для клиентов..."
                    muted=""
                    message={phone}
                    readOnly={false}
                    setMessage={setPhone}
                    leftText={<FontAwesomeIcon icon={faPhone} />}
                    theme={state.settings.theme}
                    validation={phoneValidation}
                />
                <InputTextCustom
                    controlId="email"
                    label="Адрес эл. почты"
                    required={false}
                    type="email"
                    name="email"
                    placeholder="Контактный адрес эл. почты для клиентов..."
                    muted=""
                    message={email}
                    readOnly={false}
                    setMessage={setEmail}
                    leftText={<FontAwesomeIcon icon={faEnvelope} />}
                    theme={state.settings.theme}
                    validation={emailValidation}
                />
                <OfferText theme={state.settings.theme} url={ConfigCustomService.getUrl()} />
                <CheckboxCustom
                        label={"Я внимательно прочитал(-а) оферту"} 
                        checked={offer} 
                        onChange={() => setOffer(!offer)}
                        validation={offerValidation}
                        id={"offer"}
                        name={"offer"}
                        />
                </div>
                }
                />
            <div><span></span></div>
        </div>
    );
}

export default RegisterFirmForm;