import React, { useState } from 'react';
import s from './AboutCustom.module.css';

import Galina from "./img/galina.jpg";
import Ilja from "./img//ilja.jpg";
import Dasha from "./img/dasha.jpg";
import Iluha from "./img/iluha.jpg";
import Roman from "./img/roman.jpg";
import { TeamCard } from 'omni-services-common';

const AboutCustom = () => {

 
  
  var [lawyer, setLawyer] = useState({
    
    img: Galina, 
    name: "Галина", 
    position: "Юрист, Аналитик",
    telegram: "",
    instagram: "",
    vk: "",
  });

  var [devops, setDevops] = useState({
    
    img: Ilja, 
    name: "Илья", 
    position: "DevOps, Админ, Аналитик, Программист",
    telegram: "",
    instagram: "",
    vk: "",
  });
  var [tester, setTester] = useState({
    
    img: Dasha, 
    name: "Дашенька", 
    position: "Аналитик, Тестировщик",
    telegram: "",
    instagram: "",
    vk: "",
  });
  var [coder, setCoder] = useState({
    
    img: Iluha, 
    name: "Илья", 
    position: "Программист, Аналитик",
    telegram: "",
    instagram: "",
    vk: "",
  });
  var [coder2, setCoder2] = useState({
    
    img: Roman, 
    name: "Роман", 
    position: "Программист, Аналитик",
    telegram: "",
    instagram: "",
    vk: "",
  });


    return (
      <div className='centralWrapper'>
        <h2>О нас</h2>
        <p className={s.txtWrapper}>
        Мы - Omni Services Systems - стартап из Петрозаводска (Карелия), основанный в апреле 2021 года. 
        Занимаемся разработкой программного продукта для онлайн-записи в автосервисы.  
        В своей работе мы стремимся использовать передовые технологии и инструменты для работы с информацией. 
        В разработке мы учитываем особенности бизнеса и потребности наших клиентов.
        </p>

        <div className={s.teamWrapper}>
          <TeamCard teamMate={lawyer} />
          <TeamCard teamMate={devops} />
          <TeamCard teamMate={tester} />
          <TeamCard teamMate={coder} />
          <TeamCard teamMate={coder2} />
        </div>
      </div>
    )
}


export default (AboutCustom);