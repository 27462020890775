import React, { useEffect, useState } from 'react';
import style from '../../../../special/CommonStyles.module.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { firmDefault } from '../firmDefault.jsx'
import { useRef } from 'react'; 
import FirmInitial from './FirmInitial';
import { RestCustomService } from 'omni-services-utils';
import { TabCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService.jsx';
import { FormCustom, TabAddress, TabCash, TabGallery, TabMain, TabQR, TabSchedulle, UserProcessingService } from 'omni-services-common';
import { FirmService } from '../FirmService.jsx';
import TabSettings from './tabs/tab-settings/TabSettings.jsx';
import TabAdmin from './tabs/tab-admin/TabAdmin.jsx';
import TabRequisites from './tabs/tab-requisites/TabRequisites.jsx';


export const EditFirm = (props) => {

    const params = useParams()

    const tabMainRef = useRef(null);
    const tabAddressRef = useRef(null);
    const tabScheduleRef = useRef(null);
    const tabSettingsRef = useRef(null);
    const tabRequisitesRef = useRef(null);
    const tabAdminRef = useRef(null);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    var selectedFirmNumber = params.firmNumber; //selectedNumber;

    const state = useSelector(state => state)
    
    var [error, setError] = useState();
    var [success, setSuccess] = useState();
    var [loading, setLoading] = useState();

    var [firmNumber, setFirmNumber] = useState(selectedFirmNumber);

    var [editMode, setEditMode] = useState();

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    var [firm, setFirm] = useState(FirmInitial);

    const handlingRefresh = () => {

        setLoading(true);
        FirmService.get(
            selectedFirmNumber, 
            (data) => { setFirm(data); setLoading(false); }, 
            (err) => { setError(err); setLoading(false);}
            );
    }

    useEffect(() => {
        if (selectedFirmNumber) {
            handlingRefresh(selectedFirmNumber);
        }
    }, [selectedFirmNumber])

    const refreshSelectedFirm = (firmNum) => {
        setFirmNumber(firmNum); 
        handlingRefresh(firmNum)
    }

    const deleteFirm = () => {
        FirmService.deleteByOwner(selectedFirmNumber);
    }
    const handleSubmit = (event) => {

        var main = tabMainRef.current.getResult();

        var firmEdit = firmDefault;

        firmEdit.number =  +firmNumber;
        firmEdit.name = main.name;
        firmEdit.phone = main.phone;
        firmEdit.email = main.email;

        firmEdit.address = tabAddressRef.current.getResult();

        firmEdit.settings = tabSettingsRef.current.getSettingsResult();
        firmEdit.schedule = tabScheduleRef.current.getResult();
        firmEdit.directions = tabSettingsRef.current.getDirectionsResult();

        firmEdit.requisites = tabRequisitesRef.current.getResult();

        if (UserProcessingService.hasAdminAccess() == true) {

            var adminObj = tabAdminRef.current.getResult();
            firmEdit.id = adminObj.id;
            firmEdit.ownerId = adminObj.ownerId;
            firmEdit.number = adminObj.number;
            firmEdit.rate = adminObj.rate;
            firmEdit.isEnabled = adminObj.isEnabled;
            firmEdit.isBlocked = adminObj.isBlocked;
            firmEdit.state = adminObj.state;
            firmEdit.registerDate = adminObj.registerDate;
            firmEdit.payment = adminObj.payment;


        }
        
        setLoading(true);

        console.log(firmEdit);

        FirmService
            .update(
                firmEdit, () => { 
                    setSuccess("Информация успешно обновлена!"); setLoading(false); handlingRefresh();},
                    (err) => {setError(err); setLoading(false);}
                );
    }

    var options = {
        showSelectFirm: true,
        needToLoggedIn: true,
        enableBackButton: true
    };

    const validation = () => {

        var err = null;

        err = tabMainRef.current.validation();
        if (err) {
            return err;
        }
        
        err = tabAddressRef.current.validation();
        if (err) {
            return err;
        }

        err = tabScheduleRef.current.validation();
        if (err) {
            return err;
        }

        err = tabRequisitesRef.current.validation();
        if (err) {
            return err;
        }

        if (UserProcessingService.hasAdminAccess() == true) {

            err = tabAdminRef.current.validation();

            if (err) {
                return err;
            }
        }

        return null;
    }

    return (
        <div className={style.formWrapper} id={state.settings.theme === "dark" ? style.dark : style.light}>
            <FormCustom
                title={"Фирма"}
                onSubmit={handleSubmit}
                editMode={editMode}
                setEditMode={setEditModeFunc}
                refresh={handlingRefresh}
                refreshSelectedFirm={refreshSelectedFirm}
                delete={deleteFirm}
                body={
                    <TabCustom
                        tabs={
                            UserProcessingService.hasAdminAccess() ? [
                            { name: 'main', title: 'Основное', body: <TabMain firm={firm} theme={state.settings.theme} editMode={editMode} ref={tabMainRef} url={ConfigCustomService.getUrl()} /> },
                            { name: 'address', title: 'Адрес', body: <div><TabAddress firm={firm} address={firm.address} theme={state.settings.theme} editMode={editMode} ref={tabAddressRef} url={ConfigCustomService.getUrl()} /></div> },
                            { name: 'schedulle', title: 'Расписание', body: <div><TabSchedulle schedule={firm.schedule} theme={state.settings.theme} editMode={editMode} ref={tabScheduleRef} url={ConfigCustomService.getUrl()} timezoneId={firm?.address?.geoData?.timeZoneData?.tzIdentifier} /></div> },
                            { name: 'settings', title: 'Настройки', body: <TabSettings settings={firm.settings} directions={firm.directions} theme={state.settings.theme}  editMode={editMode} ref={tabSettingsRef} url={ConfigCustomService.getUrl()} /> },
                            { name: 'requisites', title: 'Реквизиты', body: <TabRequisites firm={firm} requisites={firm?.requisites} theme={state.settings.theme} editMode={editMode} ref={tabRequisitesRef} url={ConfigCustomService.getUrl()} /> },
                            { name: 'cash', title: 'Оплата', body: <TabCash firm={firm} theme={state.settings.theme} editMode={editMode} url={ConfigCustomService.getUrl()} /> },
                            { name: 'slot', title: 'Фото', body: <TabGallery firm={firm} theme={state.settings.theme} editMode={editMode} url={ConfigCustomService.getUrl()} /> },
                            { name: 'qr', title: 'Ссылки', body: <TabQR firm={firm} theme={state.settings.theme} editMode={editMode} url={ConfigCustomService.getUrl()} /> },
                            { name: 'admin', title: 'ADMIN', body: <TabAdmin firm={firm} theme={state.settings.theme} editMode={editMode} url={ConfigCustomService.getUrl()} ref={tabAdminRef} /> },
                        ] : [
                            { name: 'main', title: 'Основное', body: <TabMain firm={firm} theme={state.settings.theme} editMode={editMode} ref={tabMainRef} url={ConfigCustomService.getUrl()} /> },
                            { name: 'address', title: 'Адрес', body: <div><TabAddress firm={firm} address={firm.address} theme={state.settings.theme} editMode={editMode} ref={tabAddressRef} url={ConfigCustomService.getUrl()} /></div> },
                            { name: 'schedulle', title: 'Расписание', body: <div><TabSchedulle schedule={firm.schedule} theme={state.settings.theme} editMode={editMode} ref={tabScheduleRef} url={ConfigCustomService.getUrl()} timezoneId={firm?.address?.geoData?.timeZoneData?.tzIdentifier}/></div> },
                            { name: 'settings', title: 'Настройки', body: <TabSettings settings={firm.settings} directions={firm.directions} theme={state.settings.theme}  editMode={editMode} ref={tabSettingsRef} url={ConfigCustomService.getUrl()} /> },
                            { name: 'requisites', title: 'Реквизиты', body: <TabRequisites firm={firm} requisites={firm?.requisites} theme={state.settings.theme} editMode={editMode} ref={tabRequisitesRef} url={ConfigCustomService.getUrl()} /> },
                            { name: 'cash', title: 'Оплата', body: <TabCash firm={firm} theme={state.settings.theme} editMode={editMode} url={ConfigCustomService.getUrl()} /> },
                            { name: 'slot', title: 'Фото', body: <TabGallery firm={firm} theme={state.settings.theme} editMode={editMode} url={ConfigCustomService.getUrl()} /> },
                            { name: 'qr', title: 'Ссылки', body: <TabQR firm={firm} theme={state.settings.theme} editMode={editMode} url={ConfigCustomService.getUrl()} /> },
                        ]
                    }
                        theme={state.settings.theme}
                    />

                }
                theme={state.settings.theme}
                error={error}
                success={success}
                options={options}
                validation={validation}
                loading={loading}
            />

        </div>
    );
}

export default EditFirm;