import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import s from './TabSingle.module.css';
import style from '../../../../../../special/CommonStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faClock, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import SlotObject from './SlotObject';
import { InputTextCustom } from 'omni-services-base';

export const TabSingle = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const state = useSelector(state => state)

    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(false);
    var [begin, setBegin] = useState();
    var [duration, setDuration] = useState();
    var [required, setRequired] = useState(props.required);
    var [firmNumber, setFirmNumber] = useState(props.selectedFirmNumber);

    const dateValidation = (date) => {

        if (!date) {
            return "Поле обязательно";
        }

        if (date.length == 0) {
            return "Поле обязательно";
        }

        const formDate = Date.parse(date);
        const nowDate = Date.parse(new Date());

        if (formDate < nowDate) {
            return "дата не может быть прошедшей";
        }

        return null;
    }

    const durationValidation = (text) => {
    
        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^\d+$/.test(text) == false) {
            return "длительность слота должна быть положительной";
        }

        return null;
    }

    const getDate = (dateTime) => {
        return dateTime?.slice(0, 10);
    }

    const getTime = (dateTime) => {
        return dateTime?.slice(11, 16)+":00";
    }

    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;
    
            err = dateValidation(begin);
            if (err != null) {
                return "Поле дата слота: " + err;
            }
    
            err = durationValidation(duration);
            if (err != null) {
                return "Поле длительность слота (в минутах): " + err;
            }
    
            return null;
        },
        
        getResult() {
            var slot = SlotObject;
            slot.date = getDate(begin);
            slot.time = getTime(begin);
            slot.duration = duration;

            console.log(slot);
            return slot;
        },

      }));

    useEffect(() => {
        setTheme(props.theme);
        setEditMode(props.editMode);
        setRequired(props.required);
    }, [props.theme, props.editMode, props.required])
    return (
        <div>
            <InputTextCustom
                theme={state.settings.theme}
                controlId="email"
                label="Дата слота"
                required={required}
                type="datetime-local"
                name="begin"
                placeholder="Дата слота..."
                muted=""
                message={begin}
                readOnly={!editMode}
                setMessage={setBegin}
                leftText={<FontAwesomeIcon icon={faCalendar} />}
                validation={dateValidation}
            />
            <InputTextCustom
                theme={state.settings.theme}
                controlId="email"
                label="Длительность слота (в минутах)"
                required={required}
                type="number"
                name="duration"
                placeholder="Длительность слота..."
                muted=""
                message={duration}
                readOnly={!editMode}
                setMessage={setDuration}
                leftText={<FontAwesomeIcon icon={faClock} />}
                validation={durationValidation}
            />
        </div>
    );
});

export default TabSingle;