import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import s from './SlotTimeListItem.module.css';

export const SlotTimeListItem = (props) => {
    const state = useSelector(state => state);
    var [slot, setSlot] = useState(props.slot);

    const getFormattedTime = (dateTime) => {
        return dateTime?.slice(0, 5);;
    } 

    return (
        <div className={s.wrapper}  id={state.settings.theme == "dark" ? s.dark: s.light}>
            <div className={s.descriptionWrapper}>
                <div>{ getFormattedTime(slot?.time) ?? "Ошибка времени"}</div>
            </div>
        </div>
    )
}

export default (SlotTimeListItem);