import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import s from './FooterCustom.module.css';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faYoutube, faInstagram, faVk, faWhatsapp, faViber, faTelegram, faTiktok } from "@fortawesome/free-brands-svg-icons"
import { useSelector } from 'react-redux';
import logo from './../../img/logo_big.png'
import botQR from './../../img/bot_qr.png'
import { NavLink } from 'react-router-dom';

const icon = <FontAwesomeIcon icon={faFacebook} />

export const FooterCustom = (props) => {
  const state = useSelector(state => state);
  return (
    <div className={"footer navbar-fixed-bottom " + s.footerBottomCustom} id={state.settings.theme == "dark" ? s.dark: s.light}>
      <footer className={"page-footer font-small blue pt-4 footer "}>
        <div className="container-fluid text-center text-md-left">
          <div className="row">
            <div className="col-md-5 mt-md-0 mt-3">
            <img
              alt=""
              src={logo}
              width="64"
              height="64"
              className="d-inline-block align-top"
            />{' '}
              <h5 className="text-uppercase">Omni-Auto</h5>
              <p>Сервисы и Специалисты. Услуги по онлайн-записи</p>
            </div>
            <hr className="clearfix w-100 d-md-none pb-3" />
            <div className="col-md-1 mb-md-0 mb-2">
              <h5 className="text-uppercase">Документы</h5>

              <NavLink to={"/documents"} >
                Документы
              </NavLink>
            </div>
            <div className="col-md-2 mb-md-0 mb-2">
              <h5 className="text-uppercase">Связь с нами</h5>

              <ul className={"list-unstyled"}>
                <li>
                  <a href="mailto:omni-services-app@yandex.ru"><FontAwesomeIcon icon={faEnvelope} /> omni-services-app@yandex.ru</a>
                </li>
                <li>
                  <a href="mailto:omni.services.app@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> omni.services.app@gmail.com</a>
                </li>
                <li>
                  <a href="tel:+79961121184"><FontAwesomeIcon icon={faPhone} /> +7 (996) 112-11-84 </a>
                </li>
                <li>
                  <a href="https://t.me/OmniAutoBot"><FontAwesomeIcon icon={faTelegram} /> OmniAutoBot</a>
                </li>
                <li>
                  <a href="#!"><FontAwesomeIcon icon={faWhatsapp} /> WhatsApp</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 mb-md-0 mb-2">
              <h5 className="text-uppercase">Бот Telegram</h5>

              <ul className={"list-unstyled"}>
                <li>
                  <div className={s.qrCodeWrapper}>
                    <img className={s.qrCodeImg} src={botQR} />
                  </div>  
                </li>
                <li>
                  <a href="https://t.me/OmniAutoBot"><FontAwesomeIcon icon={faTelegram} /> OmniAutoBot</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 mb-md-0 mb-2">
              <h5 className="text-uppercase">Мы в соцсетях:</h5>

              <ul className="list-unstyled">
                <li>
                  <a href="#!"><FontAwesomeIcon icon={faYoutube} /> YouTube</a>
                </li>
                <li>
                  <a href="https://vk.com/club224862552"><FontAwesomeIcon icon={faVk} /> VK</a>
                </li>
                <li>
                  <a href="#!"><FontAwesomeIcon icon={faTiktok} /> TikTok</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center py-3">© 2023 Copyright: Omni Services Systems
          <br/>
          <a href="#">Goncharuk</a>,&nbsp;<a href="#">Zaicev</a>,&nbsp;<a href="#">Peniäinen</a>,&nbsp;<a href="#">Koshmanov</a>,&nbsp;<a href="#">Rybakov</a>
        </div>
      </footer>
    </div>
  );
}

export default FooterCustom;