import React, { useEffect, useRef, useState } from 'react';
import s from './CreateSlot.module.css';
import style from "../../../../special/CommonStyles.module.css";
import { useDispatch, useSelector } from 'react-redux';
import SelectStaff from '../../../choose/selectStaff/SelectStaff';
import TabSingle from './tabs/tab-single/TabSingle';
import TabAuto from './tabs/tab-auto/TabAuto';
import TabRange from './tabs/tab-range/TabRange';
import TabMain from './tabs/tab-main/TabMain';
import { useParams } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom } from 'omni-services-common';
import { TabCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';
import { SlotService } from '../SlotService';


export const CreateSlot = (props) => {

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const { firmNumber } = useParams();

    const state = useSelector(state => state)
    const tabSingleRef = useRef(null);
    const tabRangeRef = useRef(null);
    const tabAutoRef = useRef(null);


    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
    var selectedFirmNumber = selectedNumber ? selectedNumber : firms?.list[0]?.number;
    var [selectedFirm, setSelectedFirm] = useState(firmNumber);

    var [tabId, setTabId] = useState('single');

    var [validateRange, setValidateRange] = useState()

    const options = {
        readOnly: false,
        canRefresh: false,
        canSave: true,
        canDelete: true,
        showMenuButtons: false,
        showFooterButtons: false,
        submitText: "Добавить",
        showSelectFirm: true,
        needToLoggedIn: true
    };


    var [success, setSuccess] = useState();
    var [error, setError] = useState();
    var [loading, setLoading] = useState();

    var [editMode, setEditMode] = useState(true);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    const handleSubmit = (event) => {
        setError(null);
        setSuccess('');
        setError('');

        if (tabId == 'single') {

            var err = tabSingleRef.current.validation();
            if (err) {
                setError(err);
                return;
            }

            var slot = tabSingleRef.current.getResult();
            slot.firmNumber = + firmNumber;

            setLoading(true);
            SlotService.addByOwner(
                slot,
                (errorList) => { 
                    props.refresh(); 
                    console.log(errorList);
                    if (!!errorList && errorList.lenght > 0) {
                        setError();
                        console.log(errorList);
                    }
                    else {
                    setSuccess("Слот успешно добавлен!"); setLoading(false); }
                },
                (err) => { setError(err); setLoading(false); }
            );

        } else if (tabId == 'range') {

            var err = tabRangeRef.current.validation();
            if (err) {
                setError(err);
                return;
            }

            var range = tabRangeRef.current.getResult();
            range.firmNumber = firmNumber;

            setLoading(true);
            SlotService.addByDateRangeByOwner(
                range,
                () => { props.refresh(); setSuccess("Слоты успешно добавлены!"); setLoading(false); },
                (err) => { setError(err); setLoading(false); }
            );
        } else if (tabId == 'auto') {

            var err = tabAutoRef.current.validation();
            if (err) {
                setError(err);
                return;
            }

            var slotAutoSchedule = tabAutoRef.current.getResult();
            slotAutoSchedule.firmNumber = firmNumber;

            setLoading(true);

            SlotService.addSlotAutoScheduleByOwner(
                slotAutoSchedule,
                () => { props.refresh(); setSuccess("Изменения сохранены!"); setLoading(false); },
                (err) => { setError(err); setLoading(false); }
            );

        }
    }

    const refreshSelectedFirm = (firmNum) => {
        // setFirmNumber(firmNum); TODO
        props.refresh();
    }

    const isAppointmentStrategyForStaff = () => {
        return selectedFirm?.settings?.appointmentStrategy == 2;
    }

    useEffect(() => {

    }, [props.setFilterDate]);

    return (
        <div className={style.formWrapper} id={state.settings.theme == "dark" ? style.dark : style.light}>
            <FormCustom
                error={error}
                success={success}
                title={"Добавить слоты"}
                options={options}
                onSubmit={handleSubmit}
                editMode={editMode}
                setEditMode={setEditModeFunc}
                refreshSelectedFirm={refreshSelectedFirm}
                theme={state.settings.theme}
                body={
                    <div>
                        {
                            isAppointmentStrategyForStaff() ? <SelectStaff /> : null
                        }
                        <TabCustom
                            tabs={[
                                { name: 'single', title: 'Слот', body: <TabSingle editMode={editMode} ref={tabSingleRef} /> },
                                { name: 'range', title: 'Диапазон', body: <TabRange editMode={editMode} ref={tabRangeRef} /> },
                                {
                                    name: 'auto', title: 'Автодобавление', body: <TabAuto
                                        editMode={editMode}
                                        selectedFirmNumber={firmNumber}
                                        setSuccess={setSuccess}
                                        ref={tabAutoRef} />
                                },
                            ]}
                            theme={state.settings.theme}
                            changeTab={(data) => { setTabId(data); }}
                        />
                    </div>
                }
                loading={loading}
            />
        </div>
    );
}

export default CreateSlot;