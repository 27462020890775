import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import s from './EditSlotItem.module.css';
import { FormCustom } from 'omni-services-common';
import { InputTextCustom } from 'omni-services-base';
import { LabelCustom } from 'omni-services-common';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';
import { SlotService } from '../SlotService';

export const EditSlotItem = (props) => {
    const state = useSelector(state => state);

    var [slot, setSlot] = useState();

    var [begin, setBegin] = useState();
    var [duration, setDuration] = useState();

    var [editMode, setEditMode] = useState(false);
    var [error, setError] = useState();
    var [meta, setMeta] = useState();

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    const getStateNameById = (isBusy) => {

        if (isBusy) {
            return {text: "Клиент записался", color: "orange"};
        } else {
            return {text: "Свободен", color: "green"};
        }
      }

    

    useEffect(() => {

        var inMeta = { 
            topLeft: <LabelCustom to={props.slot?.ticketId ? `/edit-ticket/${props.slot?.ticketId}` : ''} obj={getStateNameById((props.slot?.ticketId) || (props.slot?.userId))}/>,
            topRight: null,
            bottomLeft: /*`На дату: ${getFormattedDate(props.ticket?.slot?.begin) ?? '(отсутствует)'}`*/'',
            bottomRight: ``
        }
      
      
        setMeta(inMeta);

        setSlot(props.slot);
        setBegin(getFormattedDateTime(props.slot?.date, props.slot?.time));
        setDuration(+props.slot?.duration);

    }, [props.slot]);

    const getFormattedDateTime = (date, time) => {
        return `${date}T${time}`;
    }

    const handleDelete = () => {

        SlotService.deleteByOwner(slot, 
            (responseData) => {
                props.refresh();

                props.refresh();
                return responseData;
            }
            , (error) => { 
            //setError(error?.detail); 
        })
    }

    const handleSubmit = () => {

        slot.duration = + duration;
        slot.begin = begin;

        console.log(slot);

        SlotService.updateByOwner(slot, 
            (responseData) => {
                props.refresh();

                props.refresh();
                return responseData;
            }, 
            (error) => { 
                setError(error); 
            }
        )
    }

    return (
        <div 
            className={s.wrapper}  
            id={state.settings.theme == "dark" ? s.dark: s.light}>
            <FormCustom
                title={"Слот"}
                delete={handleDelete}
                options={[]}
                setEditMode={setEditModeFunc}
                theme={state.settings.theme}
                onSubmit={handleSubmit}
                error={error}
                body={
                <div className={s.descriptionWrapper}>
                    <InputTextCustom
                            theme={state.settings.theme}
                            controlId="email"
                            label="Дата-время"
                            required={true}
                            type="datetime-local"
                            name="password"
                            placeholder="Длительность слота..."
                            muted=""
                            message={begin}
                            setMessage={setBegin}
                            readOnly={!editMode}
                        />
                    <InputTextCustom
                            theme={state.settings.theme}
                            controlId="email"
                            label="Длительность (в минутах)"
                            required={true}
                            type="number"
                            name="password"
                            placeholder="Длительность слота..."
                            muted=""
                            message={duration}
                            setMessage={setDuration}
                            readOnly={!editMode}
                        />
                </div>
            }
            meta={meta} />

        </div>
    )
}

export default (EditSlotItem);