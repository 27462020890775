import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import s from './LeftSideMenu.module.css';
import style from "../../special/CommonStyles.module.css";
import LeftSideMenuItem from './left-side-menu-item/LeftSideMenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faBook, faBusinessTime, faClock, faDollar, faGear, faGears, faHelmetSafety, faMoon, faNoteSticky, faParking, faQuestionCircle, faTicket, faUser } from '@fortawesome/free-solid-svg-icons';
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsAuthorized, setShowMenu } from '../../rootSlice';
import { UserProcessingService } from 'omni-services-common';


export const LeftSideMenu = (props) => {

  const WORKER = 200;              // worker

  const inputRef = useRef(null);
  var myRef = useRef();

  const dispatch = useDispatch();
  const base = useSelector(state => state.base)
  const navigate = useNavigate();

  const state = useSelector(state => state)
  const user = useSelector(state => state.user)
  var [isAuthorized, setIsAuthorized] = useState();

  var [value, setValue] = useState();
  var [theme, setTheme] = useState(state.settings.theme);
  var [isExpanded, setIsExpanded] = useState();
  var firms = useSelector(state => state.firms)
  var firmNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)
  //var [firmNumber, setFirmNumber] = useState();


  const expandBlock = () => {
    setIsExpanded(!isExpanded);
  }

  const collapseBlock = () => {

  }

  const handlingClose = () => {
    dispatch(setShowMenu(false));
  }

  const handlingUser = () => {
    navigate("/user");
    handlingClose();
  }

  const handlingSlot = () => {

    if (!checkFirmNumberFill()) {
      return;
    }

    navigate(`/firm/${firmNumber}/manage-slot`);
    handlingClose();
  }

  const handlingStaff = () => {

    if (!checkFirmNumberFill()) {
      return;
    }

    navigate(`/firm/${firmNumber}/manage-staff`);
    handlingClose();
  }

  const handlingTicket = () => {

    if (!checkFirmNumberFill()) {
      return;
    }

    navigate(`/firm/${firmNumber}/manage-ticket`);
    handlingClose();
  }

  const handlingParking = () => {

    if (!checkFirmNumberFill()) {
      return;
    }

    navigate(`/firm/${firmNumber}/manage-parking-ticket`);
    handlingClose();
  }

  const handlingTheme = () => {
    handlingClose();
  }

  const handlingSignOut = () => {
    handlingClose();
    UserProcessingService.remove();
  }

  const handlingSettings = () => {
    handlingClose();
    navigate("/settings");
  }

  const checkFirmNumberFill = () => {
    
    if (!firmNumber) {
      return false;
    }

    if (isNaN(firmNumber)) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    setValue(props.message);
    setTheme(props.theme);
  }, [
    props.message,
    props.theme
  ]);

  useEffect(() => {

    if (UserProcessingService.get()) {
      setIsAuthorized(true);
      console.log("LOGGED IN");
    } else {
      setIsAuthorized(false);
      console.log("LOGGED OUT");
    }

  }, [UserProcessingService.get()]);

  if (isAuthorized == false) {
    return null;
  }

  if ((UserProcessingService.hasWorkerAccess() == false) && 
      (UserProcessingService.hasManagerAccess() == false) && 
      (UserProcessingService.hasOwnerAccess() == false)) {
    return null;
  }


  return (
    <div id={(state.settings.theme == "dark") ? s.dark : s.light} className={`${s.wrapper} ${isExpanded ? s.expanded : s.collapsed} `}>
      <LeftSideMenuItem icon={faBars} isExpanded={isExpanded} click={expandBlock} hideOnSmall={true} />
      {/*          <LeftSideMenuItem icon={faUser} isExpanded={isExpanded} title={'Пользователь'} click={handlingUser} />*/}
      {/*          <LeftSideMenuItem icon={faBusinessTime} isExpanded={isExpanded} title={'Фирма'} click={handlingFirm} />*/}
      <LeftSideMenuItem icon={faClock} isExpanded={isExpanded} title={'Слоты'} click={handlingSlot} />
      {/*          <LeftSideMenuItem icon={faHelmetSafety} isExpanded={isExpanded} title={'Персонал'} click={handlingStaff} />*/}
      <LeftSideMenuItem icon={faBook} isExpanded={isExpanded} title={'Записи'} click={handlingTicket} />
      {/*          <LeftSideMenuItem icon={faParking} isExpanded={isExpanded} title={'Стоянка'} click={handlingParking} />*/}
      <LeftSideMenuItem icon={faParking} isExpanded={isExpanded} title={'Стоянка'} click={handlingParking} />
      {/*          <LeftSideMenuItem icon={faQuestionCircle} isExpanded={isExpanded} title={'Справка'} click={handlingSignOut} />*/}
      <LeftSideMenuItem icon={faGears} isExpanded={isExpanded} title={'Настройки'} click={handlingSettings} />
    </div>
  )
}

export default LeftSideMenu;