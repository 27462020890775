export var SlotObject = {
    firmNumber: null,
    userId: null,
    workerId: null,
    ticketId: null,
    state: 0,
    duration: 0,
    date: null,
    time: null
};

export default SlotObject;